import React from "react"
import { FaHome, FaCog, FaDatabase, FaListUl, FaCheck, FaFileUpload, FaPhoneAlt, FaUser, FaRegBuilding, FaUserCog } from "react-icons/fa"
const user = JSON.parse(sessionStorage.getItem('user'))
let size = 15

const navigationSuperadmin = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "formconfig",
    title: "Setting",
    type: "collapse",
    icon: <FaCog size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formugoals",
        title: "Goals",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/goalsform"
      }
    ]
  },
  {
    id: "formuser",
    title: "Users",
    type: "collapse",
    icon: <FaUserCog size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formuser1",
        title: "User Form",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/userform"
      }
    ]
  },
  {
    id: "formomabacare",
    title: "Obama care",
    type: "collapse",
    icon: <FaListUl size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreport1",
        title: "New sale",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/newappointmentsell"
      }
    ]
  },
  {
    id: "formagency",
    title: "Agency",
    type: "collapse",
    icon: <FaRegBuilding size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formagency1",
        title: "Agency registration",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/formagency"
      }
    ]
  },
  {
    id: "formagent",
    title: "Agent",
    type: "collapse",
    icon: <FaUser size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formagent1",
        title: "Register",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/formagent"
      }
    ]
  },
  {
    id: "upload",
    title: "Upload",
    type: "collapse",
    icon: <FaFileUpload size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "uploadagent",
        title: "File agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/uploadagent"
      },
      {
        id: "agentupload",
        title: "Agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/agentupload"
      }
    ]
  },
  {
    id: "salesreport",
    title: "Sales Report",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "reportappointmentsales1",
        title: "Sales in processing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/prenewappointmentsell"
      },
      {
        id: "salesreport2",
        title: "Pending sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsell"
      },
      {
        id: "salesreport3",
        title: "Closed sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/appointmentsale"
      },
      {
        id: "salesreport4",
        title: "Delete sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/deleteAppointmentsell"
      }
    ]
  },
  {
    id: "reportbackofice",
    title: "Report BackOffice",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "reportSpeakEnglish",
        title: "Speak English",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/speak"
      },
      {
        id: "reportOver65YearsOld",
        title: "Over 65 years old",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/over"
      },
      {
        id: "reportNotInterested",
        title: "Not interested",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/notinterested"
      },
      {
        id: "reportIncorrectNumbers",
        title: "Incorrect or invalid numbers",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/incorrectnumbers"
      },
      {
        id: "reportHasworkinsurance",
        title: "Has Work Insurance",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/hasworinsurance"
      },
      {
        id: "reportNoanswer",
        title: "No answer",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/noanswe"
      }
    ]
  },
  {
    id: "statusfinishsales",
    title: "Status Finish Sales",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "statusfinishsales1",
        title: "Other Party",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesop"
      },
      {
        id: "reportstatusfinishsales2",
        title: "Cancelled",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesca"
      },
      {
        id: "reportstatusfinishsales3",
        title: "Active",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesac"
      },
    ]
  },
  {
    id: "statusfinishsalesmonth",
    title: "Report Months",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "statusfinishsales1",
        title: "List",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesmonth"
      },
    ]
  },
]

const navigatorSupervisor = [
  {
    id: "formomabacareS",
    title: "Obama care",
    type: "collapse",
    icon: <FaListUl size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreport1S",
        title: "New sale",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/newappointmentsell"
      }
    ]
  },
  {
    id: "generalsalesreport",
    title: "General Reports",
    type: "collapse",
    icon: <FaListUl size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "generalsalesreportclosed",
        title: "Closed",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/generalsalesreportclosed"
      },
      {
        id: "generalsalesreportdelete",
        title: "Delete",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/generalsalesreportdelete"
      },
      {
        id: "generalsalesreportcancelled",
        title: "Cancelled",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/generalsalesreportcancelled"
      },
    ]
  },
  {
    id: "upload",
    title: "Upload",
    type: "collapse",
    icon: <FaFileUpload size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "uploadagent",
        title: "File agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/uploadagent"
      }
    ]
  },
  {
    id: "upload2",
    title: "Phone list",
    type: "collapse",
    icon: <FaPhoneAlt size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "agentupload",
        title: "Agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/agentupload"
      },
      {
        id: "reportNodocument",
        title: "No Answer",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/noanswerselles"
      }
    ]
  },
]

const navigatorBackoffice = [
  {
    id: "upload",
    title: "Upload",
    type: "collapse",
    icon: <FaFileUpload size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "uploadagent",
        title: "File agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/uploadagent"
      },
      {
        id: "agentupload",
        title: "Agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/agentupload"
      }
    ]
  },
  {
    id: "reportbackofice",
    title: "Report BackOffice",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "reportSpeakEnglish",
        title: "Speak English",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/speak"
      },
      {
        id: "reportOver65YearsOld",
        title: "Over 65 years old",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/over"
      },
      {
        id: "reportNotInterested",
        title: "Not interested",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/notinterested"
      },
      {
        id: "reportIncorrectNumbers",
        title: "Incorrect or invalid numbers",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/incorrectnumbers"
      },
      {
        id: "reportHasworkinsurance",
        title: "Has Work Insurance",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/hasworinsurance"
      },
      {
        id: "reportNoanswer",
        title: "No answer",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/noanswe"
      }
    ]
  }
]

const navigatorAgent2 = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "formomabacare",
    title: "Obama care",
    type: "collapse",
    icon: <FaListUl size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreport1",
        title: "New sale",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/newappointmentsell"
      }
    ]
  },
  {
    id: "salesreport",
    title: "Sales",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      /* {
        id: "reportappointmentsales1",
        title: "Sales in processing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/prenewappointmentsell"
      }, */
      {
        id: "salesreport2",
        title: "Pending sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsell"
      },
      {
        id: "salesreport3",
        title: "Closed Sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportsalesbyagents"
      }
    ]
  },
  {
    id: "upload",
    title: "Phone list",
    type: "collapse",
    icon: <FaPhoneAlt size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "agentupload",
        title: "Agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/agentupload"
      },
      {
        id: "reportNodocument",
        title: "No Answer",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/noanswerselles"
      }
    ]
  }
]

const navigatorProcessor = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "salesreport",
    title: "Sales Report",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "reportappointmentsales1",
        title: "Sales in processing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/prenewappointmentsell"
      },
      {
        id: "salesreport2",
        title: "Pending sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsell"
      },
      {
        id: "salesreport3",
        title: "Closed sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/closedappointmentsalesproccesor"
      },
      {
        id: "salesreport4",
        title: "Delete sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/deleteAppointmentsell"
      }
    ]
  },
]

const navigatorQuality = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "statusfinishsales",
    title: "Status Finish Sales",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "statusfinishsales1",
        title: "Other Party",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesop"
      },
      {
        id: "reportstatusfinishsales2",
        title: "Cancelled",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesca"
      },
      {
        id: "reportstatusfinishsales3",
        title: "Active",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesac"
      },
    ]
  },
  {
    id: "statusfinishsalesmonth",
    title: "Report Months",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "statusfinishsales1",
        title: "List",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesmonth"
      },
    ]
  },
]


const navigationConfig =
  user?.profile === 7 ? navigationSuperadmin ://Superadmin
    user?.profile === 104 ? navigatorSupervisor ://Supervisor
      user?.profile === 106 ? navigatorBackoffice ://Backoffice
        user?.profile === 118 ? navigatorAgent2 ://Agente
          user?.profile === 211 ? navigatorProcessor ://Processor
            user?.profile === 219 ? navigatorQuality ://Quality
          []

export default navigationConfig
