import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Col, FormGroup, Row } from 'reactstrap'
import { useSelector } from 'react-redux';
import "../../../assets/scss/plugins/extensions/toastr.scss"
import "react-toggle/style.css"
import "react-toastify/dist/ReactToastify.css"
import "../../../assets/scss/plugins/extensions/toastr.scss"
import API from '../../../providers/api';
import moment from 'moment';

export const ModalNotePad = ({ showModal, setShowModal }) => {
    const user_id = useSelector(state => state.auth.login.state.data.user.verifyLogin.id);
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState('')
    const [lastUpdate, setLastUpdate] = useState('Not register')

    const toggleModal = () => {
    setShowModal(!showModal)
    }

    useEffect(() => {
    searchNotaPad(user_id)
    }, [])

const searchNotaPad = (user_id) => {
    API.searchNotaPad(user_id)
    .then(data => data.json())
    .then(dataJson => {
        if (dataJson.success === true) {
            setForm(dataJson.data[0].text)
            setLastUpdate(moment(dataJson.data[0].date_update).format('DD-MM-YYYY HH:mm:ss'))
        }
    })
    .catch((e) => {
        console.log("Error =>", e)
    })
}

const save = async () => {
    API.saveNotaPad(user_id, form)
    .then(data => data.json())
    .then(dataJson => {
        if (dataJson.success === true) {
            setForm(dataJson.data)
            setLastUpdate(moment().format('DD-MM-YYYY HH:mm:ss'))
        }
    })
    .catch((e) => {
        console.log("Error =>", e)
    })
}

const toggleClose = () => setShowModal(!showModal)

return (
<Modal size='lg' isOpen={showModal} toggle={toggleClose}>
    <ModalHeader toggle={toggleClose}>
    My notes
    </ModalHeader>
    <form onSubmit={save}>
    <ModalBody>
        <Row>
        <Col md="12" >
            <FormGroup>
            <textarea rows={15} className="form-control" id="comment" value={form} onChange={e => { setForm(e.target.value) }} placeholder="Digite el comentario"></textarea>
            Last update: {lastUpdate}
            </FormGroup>
        </Col>
        </Row>
    </ModalBody>
    <ModalFooter>
        <Button color="info" onClick={save}>Save</Button>{" "}
        <Button outline color="success" onClick={toggleModal} disabled={loading}>Cerrar</Button>{" "}
    </ModalFooter>
    </form>
</Modal>
)
}
